import { Flex, Link, Text } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Flex direction="column" height="100%">
          <Text size="8" weight="bold" style={{ textShadow: '0 1px 0 gray' }}>
            Good On Me LLC
          </Text>
          <Text size="4" style={{ textShadow: '0 1px 0 silver' }}>
            App Development Company
          </Text>
          <Link
            style={{
              border: '1px solid white',
              color: 'white',
              padding: '0.5rem',
              borderRadius: '2px',
              marginTop: '1rem',
              boxShadow: '0 1px 0 silver',
              backgroundColor: 'rgba(0, 0, 0, 0.05)'
            }}
            weight="bold"
            size="2"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:nate@goodonme.io"
          >
            Contact Us
          </Link>
        </Flex>
      </header>
    </div>
  );
}

export default App;
